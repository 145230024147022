<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder">IP SLA Availability</h3>
		</div>
		<ITCSpinner :loaded="loaded">
			<div class="card-body pt-2">
				<div class="row" v-for="(d, i1) in data" v-bind:key="'a' + i1">
					<div class="col-md-6" v-for="(c, i2) in d" v-bind:key="'b' + i2">
						<div
							class="row"
							style="padding: 2px"
							v-bind:style="{ background: (i1 + i2) % 2 ? '#f7f7f7' : '' }"
						>
							<div class="col-md-6">
								{{ c.label || c.name }}
							</div>
							<div class="col-md-6" v-if="c.percent">
								{{ c.percent }}% <span v-if="c.average">({{ c.average.rtt }}ms)</span>
								<span v-show="showaverageipsla"> / (Jitter:{{ c.jitter }}, </span>
								<span v-show="showaverageipsla"> Packet Loss:{{ c.pktloss }}%)</span>
							</div>
							<div class="col-md-6" v-if="!c.percent" color="grey">
								<span>Data not available</span>
							</div>
						</div>
					</div>
				</div>
				<span class="small" v-if="loaded"
					>* Availability {{ range }} <span v-show="showaveragelatency">(Average Latency)</span>
					<span v-show="showaverageipsla"> / (Jitter, Packet Loss)</span></span
				>
			</div>
		</ITCSpinner>
	</div>
</template>

<script>
function chunk(arr, size) {
	var newArr = [];
	for (var i = 0; i < arr.length; i += size) {
		newArr.push(arr.slice(i, i + size));
	}
	return newArr;
}
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

export default {
	name: '',
	data() {
		return {
			data: [],
			showaverageipsla: false,
			showaveragelatency: true,
			range: '',
		};
	},
	components: {
		ITCSpinner,
	},
	created() {
		this.loaded = false;
		this.$http.get('dashboard/swipslaavailability').then((resp) => {
			var data2 = [];
			
			if (resp.data && resp.data.data && resp.data.data.ipslaav) {				
				Object.keys(resp.data.data.ipslaav).forEach((a) => {
					data2.push(resp.data.data.ipslaav[a]);
				});
			}
            this.showaverageipsla = resp.data.data.showaverageipsla;
            if(typeof(resp.data.data.showaveragelatency) !== 'undefined'){
                this.showaveragelatency = resp.data.data.showaveragelatency;
            }
			
			this.range = resp.data.data.range;
			this.data = chunk(data2, 2);
			this.loaded = true;
		});
	},
};
</script>

<style>
</style>